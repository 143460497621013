
$z-layers: (
  "modal": (
    "base":           500,
    "close":          100,
    "header":          50,
    "footer":          10
  ),
  "loading": (
    "mask": 1000,
    "animation": 1001,
  ),
  "header": (
    "base": 900,
    "search": 5,
  ),
  // 页面悬浮层
  "float": (
    // 大于header
    "base": 910
  ),
  "toast": 1100,
  "alert": 2000,
  "top": 9999
);

@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }

  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @error "No layer found for `#{inspect($layers)}` in $z-layers map. Property omitted.";
  }

  @return map-deep-get($z-layers, $layers...);
}
@mixin text-overflow($row: 1) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

/* iOS 安全区域 兼容 */
@mixin padding-bottom-safe-area($padding: 0) {
  padding-bottom: max(#{$padding}, var(--safe-area-inset-bottom));
}

@mixin clearfix {
  &:after {
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
    content: '';
  }
}
html, body {
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: Helvetica, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
  color: #333;
}

input,
select,
textarea {
  outline: none;
  font-family: inherit;
}

img {
  vertical-align: top;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-overflow {
  @include text-overflow;
}

.text-overflow-2 {
  @include text-overflow(2);
}

.relative {
  position: relative;
}

.visibility-hidden {
  visibility: hidden;
}

.pointer {
  cursor: pointer;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.reset-a {
  text-decoration: none;
  color: inherit
}

.text-right {
  text-align: right;
}

.page-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  > .el-table {
    flex: 1;
    overflow: hidden;
  }

  > .el-pagination {
    margin-top: 20px;
  }
}

a {
  text-decoration: none;
}

.work-break-all {
  word-break: break-all;
}

.form-item-hint,
.form-item-tip {
  --el-font-size-base: 12px;
  margin: 5px 0;

  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: var(--el-text-color-secondary);
}

.column-1-descriptions {
  .el-descriptions__label {
    width: 200px;
  }

  .el-descriptions__content {
    white-space: pre-wrap;
    word-break: break-all;
  }
}

.w-full {
  width: 100%;
}

.text-primary {
  color: var(--el-color-primary);
}


.el-dialog.is-fullscreen {
  display: flex;
  flex-direction: column;

  .el-dialog__body {
    flex: 1;
    overflow: hidden;
  }

  .el-dialog__header,
  .el-dialog__footer {
    flex-shrink: 0;
  }
}