<template>
  <el-form
    ref="formRef"
    v-loading="pageLoading"
    :model="form"
    :rules="rules"
    label-position="top"
    :disabled="pageLoading"
  >
    <el-form-item :label="t('google_integration.select_ads_manager_account')" prop="manager_customer_id">
      <el-select v-model="form.manager_customer_id" filterable @change="fetchCustomerClients()">
        <el-option
          v-for="item in accessibleCustomers"
          :key="item"
          :value="item"
          :label="item"
        />
      </el-select>
    </el-form-item>

    <el-form-item :label="t('google_integration.select_customer_id')" prop="customer_id">
      <el-select
        :key="form.manager_customer_id || ''"
        v-model="form.customer_id"
        :remote-method="fetchCustomerClientsOptionRemote"
        :loading="fetchingClients"
        filterable
        remote
        :disabled="!form.manager_customer_id"
      >
        <el-option
          v-for="item in customerClients"
          :key="item"
          :value="item.id"
          :label="item.descriptive_name"
        />
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-input style="width: unset" :model-value="trackingCode" @input="onCodeInput">
        <template #prepend>
          AW-
        </template>
      </el-input>
      <LoadingButton
        type="primary"
        :disabled="!form.manager_customer_id || !form.customer_id"
        style="margin-left: 10px;"
        @click="getConversionTrackingCode"
      >
        {{ t('google_integration.request_conversion_code') }}
      </LoadingButton>
    </el-form-item>
  </el-form>
</template>

<script lang="ts" setup>
  import { reactive, ref, inject } from 'vue';
  import { MerchantType } from '@/models/merchant';
  import { useLoading } from '@/hooks';
  import { request } from '@/utils';
  import { useI18n } from 'vue-i18n';
  import { FormRules, FormInstance } from 'element-plus';
  import LoadingButton from '@/components/loading-button.vue';

  const merchant = inject('merchant') as MerchantType;
  const { t } = useI18n();

  interface IProps {
    trackingCode: string;
  }

  interface IEmits {
    (event: 'update:trackingCode', code: number): void;
  }

  const emits = defineEmits<IEmits>();
  defineProps<IProps>();

  const form = reactive({
    manager_customer_id: merchant.google_manager_id,
    customer_id: merchant.google_customer_id,
  });
  const rules: FormRules = {};

  const pageLoading = useLoading(async () => {
    await fetchAccessibleCustomers();
  });

  const accessibleCustomers = ref<Array<string>>([]);
  async function fetchAccessibleCustomers() {
    const { data } = await request.get<Array<string>>('gads/accessible_customers');
    accessibleCustomers.value = data;
    // 清空已选择
    form.manager_customer_id = '';
    form.customer_id = '';
  }

  type TCustomerClient = { id: number, descriptive_name: string };
  const customerClients = ref<Array<TCustomerClient>>([]);
  const fetchingClients = ref(false);
  async function fetchCustomerClients(name?: string): Promise<void> {
    fetchingClients.value = true;
    try {
      const { data } = await request.get<Array<TCustomerClient>>('gads/customer_clients', { params: { manager_customer_id: form.manager_customer_id, descriptive_name_cont: name || undefined } });
      customerClients.value = data;
    } finally {
      fetchingClients.value = false;
    }
  }

  function fetchCustomerClientsOptionRemote(keyword: string) {
    const kwd = (keyword || '').trim();
    fetchCustomerClients(kwd);
  }

  const formRef = ref<FormInstance>();
  async function getConversionTrackingCode() {
    if (formRef.value) {
      await formRef.value.validate();
      const { data } = await request.get<number>('/gads/conversion_tracking_id', { params: form });
      emits('update:trackingCode', data);
    }
  }

  function onCodeInput(e: any) {
    emits('update:trackingCode', e);
  }

  defineExpose({
    getCustomerId: () => {
      return form.customer_id;
    }
  });

</script>

<style scoped>
  .el-form-item .el-form-item {
    margin-bottom: 18px;
  }
</style>


